<template>
    <div style="height: 100%;" id="container"></div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
    components: {},
    data() {
        return {
            map: null,
            mapArr: [],
            showMap: false
        };
    },

    methods: {
        initAMap() {
            const self = this; // 存储当前的this
            AMapLoader.load({
                key: "6636defcef28a2bf1f1043f154835db6", // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0",
                plugins: [
                    "AMap.MouseTool",
                ],
                AMapUI: {
                    // 是否加载 AMapUI，缺省不加载
                    version: "1.1", // AMapUI 缺省 1.1
                    plugins: [], // 需要加载的 AMapUI ui插件
                },
            })
                .then((AMap) => {
                    self.map = new AMap.Map("container", {
                        // 设置地图容器id
                        viewMode: "2D", // 是否为3D地图模式
                        zoom: 15, // 初始化地图级别
                        terrain: true, //开启地形图
                        center: [117.303216,31.803165], // 初始化地图中心点位置
                    });
                    console.log(self.map);

                })
                .catch((e) => {
                    console.log(e);
                });
        },
        getMap(arr) {
        setTimeout(() => {
            if (this.map) {
                this.map.clearMap();
                arr.forEach(pathArray => {
                    var polygon = new AMap.Polygon({
                        path: pathArray
                    });
                    this.map.add(polygon);
                });
            }
        }, 300);


        }
    },

    created() {

    },

    mounted() {
        this.initAMap()

    },

}
</script>
<style lang='less' scoped>
#container {
    position: relative;

    .tools {
        position: absolute;
        right: 3%;
        bottom: 5%;
        z-index: 2;
        padding-top: 1%;
        padding-bottom: 1%;
        background-color: #ffffff;
        height: 25%;
        width: 5%;
        border-radius: 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        i {
            font-size: 0.12rem;
        }

    }
}

.active {
    color: #556bff !important;
}
</style>